import clsx from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import classes from "./styles.module.scss";
import { CTA, Description, Image, Title, Video } from "@/ui";

export default function NarrativePanel({
  className,
  containerClassName,
  titleClassName,
  title,
  description,
  img,
  cta,
  video_embed_url,
  code_iframe,
  reverse,
}) {
  if (!title && !description && !img && !video_embed_url) {
    return null;
  }

  return (
    <div className={clsx(className, containerClassName, classes.panel)}>
      <Container className="mw-xxl">
        <Row className={clsx("flex-column-reverse", { "flex-md-row-reverse": reverse, "flex-md-row": !reverse })}>
          <Col xs={12} md={video_embed_url || img || code_iframe ? 6 : 12} className="d-flex flex-column justify-content-center">
            <div className={clsx({ "ms-md-5": reverse && (video_embed_url || img), "me-md-5": !reverse && (video_embed_url || img) })}>
              <Title title={title} className={clsx(titleClassName, "mb-3")} />
              <Description description={description} />
              {cta && <CTA className="mt-4" label={cta.label} href={cta.href} variant={cta.variant} />}
            </div>
          </Col>
          
            <Col xs={12} md={6} className="d-flex flex-column justify-content-center">
              {video_embed_url ? (
                <Video video_embed_url={video_embed_url} />
              ) : img ? (
                <figure className="mb-4 mb-md-0">
                  <Image className="mw-100 w-100" img={img} size="540x" alt="" />
                </figure>
              ) : code_iframe ? (
                <figure className={`mb-4 mb-md-0 ratio-16x9`}>
                <div dangerouslySetInnerHTML={{ __html: code_iframe }} />
              </figure>
              ): null}
            </Col>
          
        </Row>
      </Container>
    </div>
  );
}

NarrativePanel.defaultProps = {
  className: "py-4 py-md-5",
  title: null,
  description: null,
  img: null,
  cta: null,
  video_embed_url: null,
  reverse: false,
};

NarrativePanel.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  cta: PropTypes.shape({
    className: PropTypes.string,
    label: PropTypes.string,
    href: PropTypes.string,
    variant: PropTypes.string,
  }),
  video_embed_url: PropTypes.string,
  reverse: PropTypes.bool,
};
